import React from "react";
import { monthNames } from "./constants";

export const devConsoleLog = (val, ...others) => {
  if (val && process.env.NODE_ENV === "development")
    console.log(val, ...others);
};

export const getFormattedDate = (
  data,
  format = "dd-mm-yyyy",
  monthInAlphabets = false
) => {
  if (data) {
    let newDate = data.indexOf("T") > 0 ? data.split("T") : null;
    if (newDate && newDate.length > 0 && format == "yyyy-mm-dd") {
      // date is js date time string
      newDate = newDate[0];
    } else {
      try {
        newDate = new Date(data);
        let dateData = {};
        dateData["yyyy"] = newDate.getFullYear();
        if (monthInAlphabets) {
          dateData["mm"] = monthNames[newDate.getMonth()];
        } else
          dateData["mm"] =
            newDate.getMonth() + 1 < 10
              ? parseInt(`0${newDate.getMonth() + 1}`)
              : newDate.getMonth() + 1;
        dateData["dd"] =
          newDate.getDate() < 10
            ? parseInt(`0${newDate.getDate()}`)
            : newDate.getDate();
        let formatArr = format.split("-");
        if (formatArr.length > 0) {
          newDate = "";
          formatArr.forEach((item, index) => {
            newDate += `${dateData[item]}`;
            if (index < formatArr.length - 1) newDate += "-";
          });
        }
      } catch (e) {
        return data;
      }
    }
    return newDate;
  }
};

export const isEmptyObject = (obj) => {
  for (let name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  return true;
};

export const getInitials = (name) => {
  var initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  console.log(initials);
  return initials;
};

export const  getTimeAgo = (timeString) => {
  const currentDate = new Date();
  const targetDate = new Date(timeString);

  const timeDifference = currentDate - targetDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
}

export const formatPhoneNumber = (phoneNumber) => {
  // Check if the number starts with '+'
  if (phoneNumber.startsWith('+')) {
    // Remove the '+' from the beginning
    return phoneNumber.slice(1);
  }
  
  // If the number doesn't start with '+', return it as is
  return phoneNumber;
}

export const getThumborUrl = (imageUrl, height = 0, width = 0) => {
  devConsoleLog("getThumborUrl", `${imageUrl}`);
  return eval("`" + imageUrl + "`");
};

export const AppContext = React.createContext();
export const AppointmentScheduleContext = React.createContext();
