import React, { useContext } from 'react'
import Loadable from "react-loadable";
import { LoadingComponent } from './Routes';

import { AppContext } from './utility';

const TemplateLoader = () => {
  const { websiteData } = useContext(AppContext);
  const { template = {} } = websiteData;
  const { name: templateName = 'serene' } = template || {};

  const Template = Loadable({
    loader: () => import(`./templates/${templateName.toLowerCase()}/index.js`),
    loading: LoadingComponent,
    // delay: 500
  });
  return (
    <Template />
  )
}

export default TemplateLoader