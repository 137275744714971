import React, { useEffect, useState } from "react";
import { LinearProgress, useMediaQuery } from "@material-ui/core";
import { AppContext, devConsoleLog, getThumborUrl } from "./utility";
import {
  API_ENDPOINT_DOCTORS,
  API_ENDPOINT_WEBSITE,
  API_SECTION_API,
  API_SECTION_PUBLIC,
  API_VERSION,
  HARBOR_API_DOCFYN_DOMAIN,
} from "./constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AppLayout = ({ children, domainName }) => {
  const navigate = useNavigate();
  const deskTopView = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const tabletView = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  const [websiteData, setWebsiteData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const {
    enterprise_code: enterpriseCode = null,
    favicon_url: faviconUrl = null,
    seoData = [],
  } = websiteData || {};

  const { title: websiteTitle = "My Clinic", meta_description: websiteDescription = "" } = seoData[0] || "";

  const getWebsiteData = async () => {
    if (!domainName) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const url = `${HARBOR_API_DOCFYN_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_ENDPOINT_WEBSITE}?domainName=${domainName}`;
    try {
      const response = await axios.get(url);
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      if (status >= 200 && status < 300) {
        setWebsiteData((prev) => ({ ...prev, ...result }));
      }
    } catch (error) {
      devConsoleLog("getWebsiteData", error);
      navigate("not-found");
    } finally {
      setIsLoading(false);
    }
  };

  const getDoctors = async () => {
    const url = `${HARBOR_API_DOCFYN_DOMAIN}${API_SECTION_API}${API_VERSION}/${API_SECTION_PUBLIC}${API_ENDPOINT_DOCTORS}?enterpriseCode=${enterpriseCode}`;
    try {
      const response = await axios.get(url);
      const { data = {}, status = null } = response || {};
      const { result = {} } = data || {};
      const { doctors = [] } = result || {};
      if (status >= 200 && status < 300) {
        setWebsiteData((prev) => ({ ...prev, doctors }));
      }
    } catch (error) {
      devConsoleLog("getWebsiteData", error);
    }
  };

  useEffect(() => {
    getWebsiteData();
  }, [domainName]);

  useEffect(() => {
    if (enterpriseCode) getDoctors();
  }, [enterpriseCode]);

  useEffect(() => {
    if (websiteTitle) {
      document.title = websiteTitle;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", websiteDescription);
      }
    }
  }, [websiteTitle]);

  useEffect(() => {
    if (faviconUrl) {
      const link = document.querySelector('link[rel="icon"]');
      if (link) {
        link.setAttribute("href", getThumborUrl(faviconUrl, 48, 48));
      }
    }
  }, [faviconUrl]);

  const contextValue = {
    deskTopView,
    mobileView,
    websiteData,
    tabletView
  };

  if (isLoading)
    return (
      <div style={{ width: "100%" }}>
        <LinearProgress color="secondary" style={{ height: "6px" }} />
      </div>
    );
  else
    return (
      <div>
        <AppContext.Provider value={contextValue}>
          {children}
        </AppContext.Provider>
      </div>
    );
};

export default AppLayout;
