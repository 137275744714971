import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#428bfe', //'#428bfe'
    },
    secondary: {
      main: '#FE385C',
    },
    text: {
      primary: "#1a181e", //'#212121',  //'#7c3647',  //'rgba(124, 54, 71, 1)',
      secondary: '#384E89', //'#384E89',  //'#FBAD23',  //'#ffffff',   //'rgba(255, 255, 255, 1)',
      paragraph: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.54)",
    },
  },
  typography: {
    fontFamily: 'Ubuntu, "Titillium Web", sans-serif',
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#384E89',
          //   backgroundColor: 'orange'
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // overrides: {
  //     MuiCssBaseline: {
  //       '@global': {
  //         '@font-face': ['Titillium Web', 'sans-serif'],
  //       },
  //     },
  //   },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}><App /></ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
