import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./AppLayout";
import ScrollToTop from "./Common/ScrollToTop";
import TemplateLoader from "./TemplateLoader";
import { DOCFYN_DOMAIN } from "./constants";

export function LoadingComponent(props) {
  if (props.error) {
    // When the loader has errored
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div>Loading...</div>;
  } else {
    // When the loader has just started
    return null;
  }
}

// const TemplateLoader = Loadable({
//   loader: () => import(`./templates/${"template1"}/index.js`),
//   loading: LoadingComponent,
//   // delay: 500
// });

const RoutesComponent = () => {
  const [domainName, setDomainName] = useState(null);
  const domain = window.location.pathname.split("/")[1];
  const origin = window.location.origin;
  const handleDomainName = () => {
    if (origin === DOCFYN_DOMAIN){
      if (window.location.pathname === '/') window.location.href = '/not-found';
      else setDomainName(domain);
    }
    else setDomainName(window.location.hostname);
  };

  useEffect(() => {
    handleDomainName();
  }, []);

  return (
    <BrowserRouter basename={origin === DOCFYN_DOMAIN ? domain : "/"}>
      <ScrollToTop />
      <AppLayout domainName={domainName}>
        <TemplateLoader />
      </AppLayout>
    </BrowserRouter>
  );
};

export default RoutesComponent;
